export const env = {

  production: false,
  pp: true,
  id: 'yes4eupp',
  region: 'europe-west3',

  _yesKey: 'dGhlIG9sYXJpYSBzZXJ2aWNlIGtleQ==',

  fb: {
    apiKey: "AIzaSyB_Hs5Q7R6-IIWhW_MAyLdC6W5DvaXSH-M",
    authDomain: "yes4eupp.firebaseapp.com",
    projectId: "yes4eupp",
    storageBucket: "yes4eupp.appspot.com",
    messagingSenderId: "136018431318",
    appId: "1:136018431318:web:4e9e61ac9f8b6d57a95af7",
    measurementId: "G-X8LCMXQLX2",
    persistence: false,
  },

  apiUrl: `https://firestore.googleapis.com/v1/projects/yes4eupp/databases/(default)/documents`,

  _cryKey: 'JpYS',

  stripePublishable: 'pk_test_8WLlgoMFsLnKWW1oQG7BVHQ8',

  algolia: {
    appId: '3M8JWUF0PM',
    apiKey: '5501a4a9e7f84050a292cdb5fa22bb12'
  },

  infobip: {
    url: 'https://api.infobip.com/'
  },

  _yesSalt: 'dGhlIG9sYXJpYSBzYWx0ZSBrZXkgc2VydmljZQ==',

  easyPayUrl: 'https://api.prod.easypay.pt/2.0/single',
  easyPayId: '7cb9b8de-28f6-4c87-aca0-f2afb0f733d7',
  easyPayKey: '6a32abbe-f69e-4693-a010-90605061ceb1',

  webPushKey: 'BDl6QvsmEd0EHYqQni4HmEysehFns7iNwH962gdqtd9PkhBPY1Ue0yLEBX3cIiptYnh0sgqoLXG0dsq8ud5c1e0',

  msAdTenant: 'e7f9d69c-13f3-457c-a04c-f555c1134fa4', // AD pp
  msAdClient: 'bcafd1ab-b988-4fd8-9b1e-98771f5453cb', // AD pp
  msRedirect: 'https://somos-pp.yesmkt.net/login/token',
};
